<template>
    <div class="d-flex flex-row vertical-scrollbar-gutter-2">
        <div class="o365-new-records-header px-1 py-1 d-flex" :style="{
                'width': dataGridControl.dataColumns.leftPinnedWidth + dataGridControl.dataColumns.centerWidth + dataGridControl.dataColumns.unusedWidth + 'px',
            }">
            <div class="hstack ms-2 align-self-end">
                <template v-if="dataGridControl.selectionControl?.isPasting">
                    <div class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <span class="ms-2 me-4">{{ $t('Pasting rows...')}}</span>
                </template>
                <span v-else class="me-4">{{$t('Add records here')}}</span>
                
                <template v-if="hasUncomittedRows">
                        <ODropdown>
                            <template #default="scope">
                                <button :disabled="isCommitting" class="btn btn-sm btn-outline-primary" :class="{'dropdown-toggle': !isCommitting}" :ref="scope.target" @click="() => scope.open()">
                                    {{$t('Commit pasted rows')}}
                                   <div v-if="isCommitting" class="spinner-border spinner-border-sm ms-2" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </template>
                            <template #dropdown="scope">
                                <div class="dropdown-menu show" :ref="scope.container">
                                    <button class="dropdown-item" :title="$t('Adds rows one by one (each row will send a separate save request)')" 
                                        @click="() => { savePastedRows(false); scope.close();}" type="button">{{$t('One By One')}}</button>
                                    <button class="dropdown-item" :title="$t('Adds all rows simultaneously. If a row fails, the import will be rolled back and no rows will be added)')" 
                                        @click="() => { savePastedRows(true); scope.close()}" type="button">{{ $t('Bulk') }}</button>
                                </div>
                            </template>
                        </ODropdown>
                    <button class="btn btn-sm btn-outline-primary ms-2" @click="clearPastedRows">
                        {{$t('Clear pasted rows')}}
                    </button>
                </template>
                <!-- <span v-else class="text-muted hide-sm"> -->
                    <!-- {{$t('Click star and use ctrl + v to paste rows')}} -->
                <!-- </span> -->
            </div>
           
        </div>
        <div v-if="dataGridControl.dataColumns.hasPinnedRightColumns" class="o365-new-records-header-right h-100" 
            :class="{'ms-auto': !dataGridControl.props.disableRightPinnedOffset }"
            :style="{'min-width': dataGridControl.dataColumns.rightPinnedWidth + 'px'}"></div>
    </div>

    <div v-if="false && !hidefooter && isBottom && dataGridControl.dataObject?.data.length > 0" 
        class="o365-new-records-footer bottom-panel border-end" 
        :style="{
            'max-width': dataGridControl.dataColumns.leftPinnedWidth + dataGridControl.dataColumns.centerWidth + 'px'
        }">
    </div>


    <div class="o365-new-records-container shadow-sm" style="display: flex; flex-direction: row; overflow-y: auto;">
             
        <div class="o365-grid-body" ref="newRecordsBodyRef">
            <!-- :activeRows="dataGridControl.props.activeRows" -->
            <ODataGridDataList :data="scrollData" :dataGridControl="dataGridControl" :viewportRefFunction="el=>containerRef=el" class="" :dataLength="data.length"
                :dataHeight="dataGridControl.props.getRowHeight ? vs_totalHeight : null" :handleScroll="handleScroll" navigationContainer="N"> 
                <template #left="{row, rowIndex}">
                    <DataGridBodyRow :row="row" pinned="left" :rowIndex="rowIndex" 
                        :rowClass="{ 'o365-new-record-row': row.item?.isNewRecord }" rowStyle=""
                        :getSelectionClass="getSelectionClass" 
                        :updateEditorRef="dataGridControl.updateEditorRef" container="N" 
                        :activeEditLocation="activeEditLocation" :viewportRef="containerRef" />
                </template>
                <template #center="{row, rowIndex}">
                    <DataGridBodyRow :row="row" :rowIndex="rowIndex" 
                        :rowClass="{ 'o365-new-record-row': row.item?.isNewRecord }" rowStyle=""
                        :getSelectionClass="getSelectionClass" 
                        :updateEditorRef="dataGridControl.updateEditorRef" container="N" 
                        :activeEditLocation="activeEditLocation" :viewportRef="containerRef" />
                </template>
                <template #right="{row, rowIndex}">
                    <DataGridBodyRow :row="row" pinned="right" :rowIndex="rowIndex" 
                        :rowClass="{ 'o365-new-record-row': row.item?.isNewRecord }" rowStyle=""
                        :getSelectionClass="getSelectionClass" 
                        :updateEditorRef="dataGridControl.updateEditorRef" container="N" 
                        :activeEditLocation="activeEditLocation" :viewportRef="containerRef" />
                </template>
                
            </ODataGridDataList>
        </div>
    </div>
    <div v-if="!isBottom"
        class="o365-new-records-footer d-flex" 
        :style="{
            'max-width': dataGridControl.dataColumns.leftPinnedWidth + dataGridControl.dataColumns.centerWidth + dataGridControl.dataColumns.unusedWidth + 'px'
        }">
        <span class="ms-2 mb-1 align-self-end">{{dataGridControl.props.disableFilterRow ? $t('Existing records') : $t('Filter for existing records')}}</span>
    </div>

</template>


<script setup lang="ts">
import type DataGridControl from 'o365.controls.DataGrid.ts';

import ODataGridDataList from 'o365.vue.components.DataGrid.DataList.vue';
import ONewRecord from 'o365.vue.components.DataGrid.NewRecord.vue';
import useDataGridNavigation from 'o365.vue.composables.DataGridNavigation.ts';
import useDataGridHover from 'o365.vue.composable.DataGrid.Hover.ts';
import useVirtualScroll from 'o365.vue.composables.VirtualScroll.ts';
import useAsyncComponent from 'o365.vue.composables.AsyncComponent.ts';
import DataGridBodyRow from 'o365.vue.components.DataGrid.BodyRow.vue';
//import Overlay from 'o365.vue.components.Overlay.vue';
import { computed, onMounted, onUnmounted, toRef, ref, nextTick } from 'vue';

const ODropdown = useAsyncComponent('o365.vue.components.DropDown.vue');
const props = defineProps<{
    dataGridControl: DataGridControl,
    widthScrollContainerRef: HTMLElement,
    isBottom?: boolean,
    hidefooter?: boolean,
}>();

const data = computed(() => {
    if (props.dataGridControl.dataObject) {
        return props.dataGridControl.dataObject.batchDataEnabled
            ? props.dataGridControl.dataObject.batchData.data
            : [];
    } else {
        return props.dataGridControl.newData?.data;
    }
});

const hasUncomittedRows = computed(() => {
    return data.value.some(x => x.disableSaving);
});

const lastIndex = computed(() => {
    return data.value[data.value.length - 1]?.index;
});

const activeEditLocation = computed(() => {
    return props.dataGridControl.gridFocusControl?.editMode ? props.dataGridControl.gridFocusControl.activeCell : null;
});

// const hasErrorRows = computed(() => {
//     return props.dataGridControl.dataObject.batchData.some(row => row.error);
// });


const containerRef = ref(null)
// const { navigationControl } = useDataGridNavigation({
    // containerRef: containerRef,
    // gridControl: toRef(props, 'dataGridControl'),
    // rowSelector: '.o365-new-record',
    // cellSelector: '.o365-body-cell',
    // forceEditMode: true,
    // widthScrollerRef: toRef(props, 'widthScrollContainerRef'),
    // enableDrag: false,
    // disableSelectionRendering: true,
    // reverseY: true,
// });

const { scrollData, handleScroll, updateData: updateVirtualScrollData, 
    totalHeight: vs_totalHeight, 
    getPosByIndex: vs_getPosByIndex, 
    getRowHeightByIndex: vs_getRowHeightByIndex,
    updateRowHeight: vs_updateRowHeight,
    updateWatcher: vs_updateWatcher
    } = useVirtualScroll({
    dataRef: data,
    watchTarget: () => props.dataGridControl.dataObject.batchDataEnabled ? props.dataGridControl.dataObject.batchData.storage.updated : undefined,
    itemSize: props.dataGridControl.props.rowHeight,
    elementRef: containerRef,
    itemsToRender: props.dataGridControl.props.itemsToRender,
    getRowHeight: props.dataGridControl.props.getRowHeight,
});

const originalSaveSetting = props.dataGridControl.dataObject?.disableSaveOncurrentIndexChange;
const autosaveToDataBase = computed({
    get() { return !props.dataGridControl.dataObject?.disableSaveOncurrentIndexChange ?? true; },
    set(value) { 
        if (props.dataGridControl.dataObject) {
            props.dataGridControl.dataObject.disableSaveOncurrentIndexChange = !value;
        }
    }
});

const utils = {
    save: () => props.dataGridControl.dataObject?.batchData?.saveChanges ? props.dataGridControl.dataObject?.batchData?.saveChanges(undefined, false) : null ,
    dataObject: props.dataGridControl.dataObject,
    autoSave: autosaveToDataBase
};

function rowClassMap(rowIndex) {
    return props.dataGridControl.gridSelectionInterface?.selectionClassMap?.[rowIndex];
}

const newRecordsBodyRef = ref<HTMLElement|null>(null);

useDataGridHover({
    viewportRef: containerRef,
    rowClass: 'o365-new-record'
});

onMounted(() => {
    if (props.dataGridControl.props.newRecordsApi?.focusFirstEditableCell) {
        props.dataGridControl.props.newRecordsApi.focusFirstEditableCell(props.dataGridControl);
    } else if (!props.dataGridControl.showNewRecordsPanel && data.value[0] && typeof data.value[0].index === 'number') {
        props.dataGridControl.navigation.focusFirstEditableCell(data.value.length - 1);
        props.dataGridControl.navigation.enterEditMode();
        // cellNavigationController.focusFirstEditableCell(data.value[0].index);
    }


    // newRecordsBodyRef.value?.addEventListener('click', (e: MouseEvent) => {
        // const newRecordRow = (e.target as HTMLElement)?.closest<HTMLElement>('.o365-header-row.o365-body-row');
        // if (newRecordRow) {
            // const index = parseInt(newRecordRow.dataset.o365Rowindex as string);
            // props.dataGridControl.setCurrentIndex(index);
        // }
    // });

    if (props.dataGridControl.container) {
        const viewport = newRecordsBodyRef.value?.querySelector('.o365-body-center-cols-viewport') as HTMLElement;
        const scrollbar = props.dataGridControl.container.querySelector('.o365-body-horizontal-scroll-viewport') as HTMLElement;
        if (viewport && scrollbar) {
            viewport.scrollLeft = scrollbar.scrollLeft;
        }
    }

    let skipCheck = false;
    let scrollDebounce;
    newRecordsBodyRef.value?.querySelector('.o365-body-center-cols-viewport')?.addEventListener('scroll', (e) => {
        if (!skipCheck) {
            if (props.dataGridControl.isScrollingFromScrollbar || props.dataGridControl.isScrollingFromMainContainer) { return; }
        }

        props.dataGridControl.isScrollingFromScrollbar = true;
        props.dataGridControl.isScrollingFromMainContainer = true;
        skipCheck = true;
        if (scrollDebounce) { window.clearTimeout(scrollDebounce); }
        scrollDebounce = window.setTimeout(() => {
            props.dataGridControl.isScrollingFromScrollbar = false;
            props.dataGridControl.isScrollingFromMainContainer = false;
            skipCheck = false;
        }, 100);

        const container = props.dataGridControl.container;

        if (container) {
            const scrollLeft = (e.target as HTMLElement).scrollLeft;
            const scrollbar = container.querySelector('.o365-body-horizontal-scroll-viewport') as HTMLElement;
            if (scrollbar) {
                scrollbar.scrollLeft = scrollLeft;
            }
            container.querySelectorAll<HTMLElement>(".o365-grid-container").forEach((el) => el.style.transform = "translateX(-" + scrollLeft + "px)");
            container.querySelectorAll<HTMLElement>(".o365-body-center-cols-viewport").forEach((el) => el.scrollLeft = scrollLeft);
        }
    });

    window.dispatchEvent(new Event('resize'));
});

onUnmounted(() => {
    window.dispatchEvent(new Event('resize'));
    if (props.dataGridControl.dataObject) {
        props.dataGridControl.dataObject.disableSaveOncurrentIndexChange = originalSaveSetting;
    }
});

const isCommitting = ref(false);

function savePastedRows(pBulk: boolean) {
    const pastedItems = data.value.filter(item => item.disableSaving);
    isCommitting.value = true;
    if (pBulk) {
        props.dataGridControl.dataObject.recordSource.bulkCreateItems(pastedItems).then(() => {
            pastedItems.forEach(item => item.disableSaving = false);
        }).finally(() => {
            isCommitting.value = false;
        });
    } else {
        pastedItems.forEach(item => item.disableSaving = false);
        props.dataGridControl.dataObject.batchData.saveChanges(undefined, false).catch(ex => {
            pastedItems.forEach(item => {
                if (item.hasChanges) {
                    item.disableSaving = true;
                }
            });
        }).finally(() => {
            isCommitting.value = false;
        });
    }
}

async function clearPastedRows() {
    data.value.filter(item => item.disableSaving).map(item => {
        return props.dataGridControl.dataObject.batchData.getInversedIndex(item.index);
    }).sort((a,b) => b - a).forEach(index => {
        props.dataGridControl.dataObject.batchData.storage.removeItem(index);
    });
    // data.value.filter(item => item.disableSaving).forEach(item => item.cancelChanges());
}

function getSelectionClass(colIndex, rowIndex) {
    if (!props.dataGridControl.gridSelectionInterface?.selectionClassMap) { return; }

    const classMap = props.dataGridControl.gridSelectionInterface?.selectionClassMap['N']?.[rowIndex]?.[colIndex];
    let className;
    if (classMap) {
        className = classMap.join(' ');
    }
    const activeCell = props.dataGridControl.gridFocusControl.activeCell;
    if (activeCell === `N_${colIndex}_${rowIndex}`) {
        className = (className ? className + ' ' : '') + 'o365-focus-cell';
    }

    return className;
}

</script>
